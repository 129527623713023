@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountFinancialDocuments {
    &Mobile {
        .MyAccountTableMobile-Item_customComponent {
            @include mobile {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &-SalesDepartment {
        display: block;
        line-height: 2;

        @include desktop {
            word-break: break-all;
            white-space: nowrap;
        }
    }

    th,
    td {
        &.MyAccountFinancialDocuments-Table {
            &_doc_date,
            &_sale_date,
            &_payment_date,
            &_delay_days {
                @include desktop {
                    min-width: 100px;
                    width: 100px;
                }
            }

            &_currency {
                @include desktop {
                    min-width: 80px;
                    width: 80px;
                }
            }

            &_value,
            &_paid,
            &_difference {
                @include desktop {
                    min-width: 90px;
                    width: 90px;
                }
            }

            &_type,
            &_reference,
            &_order_number,
            &_number,
            &_status {
                @include desktop {
                    width: 130px;
                    min-width: 130px;
                }
            }
        }
    }

    td {
        &.MyAccountFinancialDocuments-Table {
            &_value,
            &_paid,
            &_difference {
                text-align: end;
            }

            &_delay_days,
            &_currency {
                text-align: center;
            }
        }
    }

    &-Days {
        &_isDelayed {
            color: $default-error-base-color;
        }
    }
}
