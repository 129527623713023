@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountSort {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    align-items: center;

    @include desktop {
        grid-column-gap: 24px;
    }

    .FieldSelect {
        min-width: 260px;
    }
}
