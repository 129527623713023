@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountArrangements {
    @include desktop {
        --accordion-left-margin: 116px;

        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10%;
    }

    &-List {
        .Breadcrumbs-List {
            padding-block: 0 16px;
        }
    }

    &-Detail {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr;
        padding-inline: 24px;
        padding-block: 24px;
        background-color: #{map-get($default-neutral, 10)};

        dt {
            color: map-get($default-neutral, 500);
        }

        dd {
            @include mobile {
                margin-block-start: -12px;
            }
        }

        .Button {
            --button-padding: 13px;

            margin-block-start: 16px;

            .ChevronIcon {
                margin-inline-start: 8px;

                @include desktop {
                    display: none;
                }
            }

            @include desktop {
                grid-column: 1/3;
                margin-block-start: 32px;
            }
        }

        @include desktop {
            position: sticky;
            inset-block-start: var(--header-total-height);
            padding-inline: 56px;
            padding-block: 56px;
            grid-gap: 24px 10%;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-Search {
        & > .SearchField {
            max-width: inherit;
            width: 100%;
        }

        margin-block-end: 32px;

        @include desktop {
            margin-block-end: 48px;
        }
    }

    &-Item {
        display: block;

        &:focus,
        &_active {
            & > .MyAccountArrangements-Content {
                background-color: #{map-get($default-primary, 700)};
                color: var(--color-white);

                & > .MyAccountArrangements-Link {
                    color: var(--color-white);
                }
            }
        }

        & > span {
            font-size: 14px;
            line-height: 2;
            color: map-get($default-neutral, 500);
        }
    }

    &-Link {
        display: flex;
        align-items: center;
        color: #{map-get($default-primary, 700)};

        &_back {
            margin-block-end: 16px;
            color: map-get($default-secondary, 800);

            .ChevronIcon {
                margin-inline-end: 10px;
            }
        }
    }

    &-Content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        padding-inline: 16px;
        font-weight: 600;
        background-color: var(--color-white);
        color: #{map-get($default-primary, 700)};
        border: {
            style: solid;
            color: #{map-get($default-primary, 700)};
            width: 2px;
        }

        z-index: 1;
        margin-block-start: 32px;

        @include desktop {
            padding-inline: 24px;
            margin-block-start: 24px;
        }
    }

    .Accordion {
        --accordion-toggle-backgroud: transparent;
        --accordion-toggle-border: transparent;
        --accordion-toggle-color: var(--primary-base-color);
        --accordion-toggle-hover-backgroud: transparent;
        --accordion-toggle-hover-border: transparent;
        --accordion-toggle-hover-color: var(--primary-base-color);

        &:not(.Accordion_open) {
            --accordion-header-border: #{map-get($default-neutral, 200)};
            --accordion-header-color: #{map-get($default-neutral, 500)};
            --accordion-toggle-color: #{map-get($default-neutral, 500)};
            --accordion-toggle-hover-color: var(--primary-base-color);
        }

        &-Header,
        &-Body {
            border-width: 2px;

            @include mobile {
                padding-inline: 16px;
            }
        }

        &-Toggle {
            width: 16px;

            @include desktop {
                width: 20px;
            }
        }

        &-Header {
            height: 56px;

            @include desktop {
                height: 64px;
            }
        }

        & > .MyAccountArrangements-Item {
            &::before {
                content: '';
                position: absolute;
                inset-block-end: 0;
                width: 0;
                height: calc(100% + 30px);
                border-left: 2px dashed map-get($default-neutral, 200);

                @include mobile {
                    inset-inline-start: 50%;
                    transform: translateX(-50%);
                }

                @include desktop {
                    inset-inline-start: calc(var(--accordion-left-margin) * 0.5 * -1);
                    height: calc(100% + 20px);
                }
            }

            @include desktop {
                &::after {
                    content: '';
                    position: absolute;
                    height: 0;
                    inset-block-start: 26px;
                    inset-inline-start: calc(var(--accordion-left-margin) * 0.45 * -1);
                    width: var(--accordion-left-margin);
                    border-top: 2px dashed map-get($default-neutral, 200);
                }
            }

            &:last-child {
                &::before {
                    inset-block-start: -26px;
                    height: 48px;

                    @include desktop {
                        inset-block-start: -20px;
                    }
                }
            }

            margin-inline-start: var(--accordion-left-margin);
        }
    }
}
