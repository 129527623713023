@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountTable {
    &-Table {
        table-layout: auto;
        width: 100%;
        margin-block-end: 0;
        z-index: 1;

        tbody {
            z-index: 0;
        }
    }

    &-Wrapper {
        max-width: 100%;
        overflow-x: auto;
    }

    &-Row {
        background-color: var(--color-white);

        &_isEven {
            background-color: map-get($default-neutral, 50);
        }
    }

    &-Cell {
        &_isCollapsed {
            .MyAccountTable-Cell {
                max-width: inherit;
            }
        }

        &_Collapsed {
            width: 0;
        }
    }

    &-Toggle {
        --button-padding: 0;
        --button-height: 36px;
        --button-hover-padding: 36px;
        --button-hover-height: 36px;

        --hollow-button-padding: 0;
        --hollow-button-height: 36px;
        --hollow-button-hover-padding: 0;
        --hollow-button-hover-height: 36px;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 52px;
        cursor: pointer;
        border: {
            style: solid;
            color: var(--primary-base-color);
            width: 1px;
        }

        background-color: transparent;
        color: var(--primary-base-color);

        @include mobile {
            margin-inline: auto;
        }

        @include desktop {
            --button-height: 40px;
            --button-hover-height: 40px;
            --hollow-button-height: 40px;
            --hollow-button-hover-height: 40px;

            height: 40px;
            width: 60px;
        }
    }

    &Desktop {
        display: grid;
    }

    &Mobile {
        &:not(&_isCollapsed) {
            background-color: map-get($default-neutral, 50);
            padding-inline: 16px;
            padding-block: 24px;
        }

        &:not(&_isCollapsedParent):not(:last-of-type) {
            margin-block-end: 32px;
        }

        &-Item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 24px;

            &_customComponent {
                grid-template-columns: 1fr;
            }

            &[hidden] {
                display: none;
                visibility: hidden;
            }

            &:not(:last-of-type) {
                margin-block-end: 24px;
            }
        }

        &-Label {
            color: var(--table-head-color);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-Value {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
