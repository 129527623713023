@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountUserManage {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 48px;
    align-items: flex-start;

    &_isPermissionManage {
        grid-template-columns: 1fr;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include narrow-desktop {
            grid-template-columns: 1fr 0.75fr; /* stylelint-disable-line number-max-precision */
        }

        @include tablet {
            grid-template-columns: 1fr;
        }
    }

    .Field {
        &-Wrapper {
            &:first-of-type {
                .Field {
                    margin-block-start: 0;
                }
            }
        }
    }

    &-TabSwitcher {
        display: flex;
        align-items: center;

        .Button {
            --button-height: 53px;
            --button-padding: 9px 16px;
            --button-hover-height: 53px;
            --button-hover-padding: 9px 16px;
            --hollow-button-height: 53px;
            --hollow-button-padding: 9px 16px;
            --hollow-button-hover-height: 53px;
            --hollow-button-hover-padding: 9px 16px;
            --button-background: var(--color-black);
            --button-border: var(--color-black);
            --button-hover-background: var(--color-black);
            --button-hover-border: var(--color-black);
            --hollow-button-background: var(--color-white);
            --hollow-button-border: var(--color-black);
            --hollow-button-color: var(--color-black);
            --hollow-button-hover-background: var(--color-black);
            --hollow-button-hover-border: var(--color-black);

            @include mobile {
                font-size: 12px;
                line-height: 1.5;
            }

            @include desktop {
                --button-height: 60px;
                --button-padding: 16px 24px;
                --button-hover-height: 60px;
                --button-hover-padding: 16px 24px;
                --hollow-button-height: 60px;
                --hollow-button-padding: 16px 24px;
                --hollow-button-hover-height: 60px;
                --hollow-button-hover-padding: 16px 24px;

                font-size: 16px;
                line-height: 1.75;
            }
        }
    }

    &-Form {
        padding-inline: 16px;
        padding-block-start: 24px;
        padding-block-end: 24px;
        background-color: map-get($default-neutral, 10);

        .Form {
            margin-block-end: 8px;
        }

        .Field {
            &-Wrapper {
                &:not(:first-of-type) {
                    margin-block-start: 24px;

                    @include mobile {
                        margin-block-start: 14px;
                    }
                }
            }
        }

        @include desktop {
            padding-inline: 32px;
            padding-block-start: 38px;
            padding-block-end: 64px;

            .Form {
                margin-block-start: 8px;
                margin-block-end: 20px;
            }
        }
    }

    &-Permissions {
        &Header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 100%;
            background-color: var(--color-black);
            color: var(--color-white);
            font-weight: 600;
            font-size: 12px;
            line-height: 1.5;

            @include desktop {
                margin-block-start: -60px;
                font-size: 16px;
                line-height: 1.75;
            }

            @include tablet {
                margin-block-start: 0;
            }
        }

        &Body {
            padding-inline: 16px;
            padding-block-start: 24px;
            padding-block-end: 24px;
            background-color: map-get($default-neutral, 10);

            @include desktop {
                padding-inline: 32px;
                padding-block-start: 38px;
                padding-block-end: 48px;
            }
        }
    }

    &-Search {
        margin-block-end: 32px;

        @include desktop {
            margin-block-end: 49px;
        }

        & > .SearchField {
            max-width: 100%;
            width: 100%;
        }
    }

    &-Selector {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 24px;

        .Field-Wrapper {
            overflow: hidden;
            max-width: 100%;

            .Field-RadioLabel {
                & > span {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &-Item {
        &:not(:last-of-type) {
            margin-block-end: 24px;
        }

        .Button {
            --button-height: 36px;
            --button-padding: 5px 24px;
            --button-hover-height: 36px;
            --button-hover-padding: 5px 24px;
            --hollow-button-height: 36px;
            --hollow-button-padding: 5px 24px;
            --hollow-button-hover-height: 36px;
            --hollow-button-hover-padding: 5px 24px;

            @include tablet {
                width: auto;
                margin-block-start: inherit;
            }

            @include mobile {
                margin-block-start: 14px;
            }
        }
    }

    &-Submit {
        display: flex;
        align-items: center;
        margin-block-start: 48px;
        margin-inline-start: auto;

        .SaveIcon {
            height: 15px;
            width: 15px;
            margin-inline-end: 10px;
        }
    }
}
