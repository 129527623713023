@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountUserPermission {
    @include desktop {
        --accordion-left-margin: 40px;
    }
    --accordion-left-margin: 26px;

    .Field_type_checkbox {
        .Field-CheckboxLabel {
            text-align: start;

            & > span {
                display: block;
                margin-block-start: -2px;

                @include desktop {
                    margin-block-start: -1px;
                }
            }
        }

        [type='checkbox'] {
            & + .input-control {
                --checkbox-width: 24px;
                --checkbox-height: 24px;

                margin-inline-end: 16px;
                z-index: 1;
            }
        }
    }

    & > .Accordion {
        overflow: hidden;
    }

    .Accordion {
        --accordion-header-backgroud: transparent;
        --accordion-header-border: transparent;
        --accordion-header-color: var(--color-black);

        --accordion-toggle-backgroud: transparent;
        --accordion-toggle-border: transparent;
        --accordion-toggle-color: var(--color-black);

        --accordion-toggle-hover-backgroud: transparent;
        --accordion-toggle-hover-border: transparent;
        --accordion-toggle-hover-color: var(--primary-base-color);

        &-Label {
            .Tooltip {
                margin-inline-start: 8px;

                &-Content {
                    font-size: 14px;
                    padding: 8px 12px;
                    background: var(--notification-warning-background);
                    border: 1px solid var(--notification-warning-color);
                    color: var(--notification-warning-color);
                }
            }
        }

        &-Header {
            height: inherit;
            padding-inline: 0;
            font-weight: 400;

            @include desktop {
                justify-content: flex-start;
            }
        }

        &-Toggle {
            height: 20px;
            width: 20px;
            margin-inline-start: 8px;

            .ChevronIcon {
                width: 6px;
            }
        }

        & > .Accordion {
            margin-inline-start: var(--accordion-left-margin);

            &::before {
                content: '';
                position: absolute;
                inset-block-end: 0;
                inset-inline-start: calc(var(--accordion-left-margin) * 0.55 * -1);
                width: 0;
                height: calc(100% + 38px);
                border-left: 2px dashed map-get($default-neutral, 200);

                @include desktop {
                    inset-inline-start: calc(var(--accordion-left-margin) * 0.7 * -1);
                    height: calc(100% + 30px);
                }
            }

            &::after {
                content: '';
                position: absolute;
                height: 0;
                inset-block-start: 13px;
                inset-inline-start: calc(var(--accordion-left-margin) * 0.35 * -1);
                width: var(--accordion-left-margin);
                border-top: 2px dashed map-get($default-neutral, 200);

                @include desktop {
                    inset-block-start: 14px;
                    inset-inline-start: calc(var(--accordion-left-margin) * 0.6 * -1);
                    width: var(--accordion-left-margin);
                }
            }

            &:first-child {
                background-color: red !important
            }

            &:last-child {
                &::before {
                    inset-block-start: -26px;
                    height: 40px;

                    @include desktop {
                        inset-block-start: -20px;
                        height: 35px;
                    }
                }
            }
        }
    }
}
