@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --my-account-content-background: #{$white};
    --my-account-content-border: 1px solid rgba(10, 9, 3, 0.16);
}

.MyAccount {
    --breadcrumbs-height: 64px;
    --footer-total-height: 0px;

    background: var(--my-account-background);

    .Pagination {
        display: flex;
        margin-block-start: 32px;

        @include desktop {
            margin-block-start: 48px;
            margin-inline-start: auto;
            margin-inline-end: 0;
        }

        &-ListItem {
            &::before {
                display: none;
            }
        }
    }

    &-Wrapper {
        padding-block: 64px;

        @include mobile {
            padding-block: 32px;
        }
    }

    &-TabContent {
        background: var(--my-account-content-background);
    }

    &-Heading {
        text-transform: none;
        margin: 0;
        margin-block-end: 48px;

        @include mobile {
            font-size: 20px;
            margin-block-end: 24px;
        }
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }
}
