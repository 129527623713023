@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountHeader {
    --filter-body: #{map-get($default-secondary, 50)};
    --input-height: 40px;
    --input-font-size: 1.4rem;
    --input-line-height: 2;
    --input-padding-inline: 18px;
    --input-padding-block: 6px;
    --hollow-secondary-button-background: var(--filter-body);
    --hollow-secondary-button-height: 40px;
    --hollow-secondary-button-hover-height: 40px;
    --secondary-button-height: 40px;
    --secondary-button-hover-height: 40px;

    z-index: 2;

    &_hasContent {
        margin-block-end: 48px;
    }

    &-Button {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.75;
        cursor: pointer;

        &_active {
            color: map-get($default-secondary, 800);
        }

        .FilterIcon {
            margin-inline-end: 12px;
        }

        .ChevronIcon {
            margin-inline-start: 8px;
            margin-block-end: 2px;
            width: 6px;
        }
    }

    &-Header {
        position: absolute;
        inset-block-start: -55px;
        inset-inline-end: 0;
        display: flex;
        align-items: center;
        z-index: 1;

        @include tablet {
            inset-block-start: -90px;
        }

        @include desktop {
            inset-block-start: -94px;

            .MyAccountHeader-Button_sortable {
                margin-inline-end: 48px;
            }
        }
    }

    & > .MyAccountSort {
        @include mobile {
            margin-block-end: 32px;
        }
    }

    &-Children {
        &_hasFilter {
            margin-block-start: 24px;
        }
    }

    &-Body {
        background-color: var(--filter-body);
        padding-inline: 16px;
        padding-block-start: 16px;
        padding-block-end: 32px;
        z-index: 0;

        @include desktop {
            padding-inline: 48px;
            padding-block-start: 24px;
            padding-block-end: 64px;
        }

        .MyAccountHeader-Button {
            margin-block-end: 24px;
        }

        .MyAccountFilter {
            @include desktop {
                display: grid;
                grid-column-gap: 75px;
                grid-row-gap: 24px;
                align-items: end;
                grid-template-columns: repeat(4, 1fr);
            }

            @include narrow-desktop {
                grid-template-columns: repeat(3, 1fr);
            }

            @include tablet {
                grid-template-columns: repeat(2, 1fr);
            }

            .Field {
                margin-block-start: 0;

                @include mobile {
                    margin-block-end: 24px;
                }
            }

            .Field-Label {
                font-size: 14px;
                line-height: 2;
            }

            &-Button {
                &_isClearable {
                    grid-column: 3/4;

                    @include narrow-desktop {
                        grid-column: 2/3;
                    }

                    @include tablet {
                        grid-column: 1/2;
                    }

                    @include mobile {
                        margin-block-end: 24px;
                    }
                }

                &_isConfirmable {
                    grid-column: 4/4;

                    @include narrow-desktop {
                        grid-column: 3/3;
                    }

                    @include tablet {
                        grid-column: 2/2;
                    }
                }
            }
        }
    }
}
