@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Account {
    --header-logo-width: 130px;
    --header-logo-height: 35px;
    --content-wrapper-width: 910px;

    min-height: calc(100vh - var(--header-top-menu-height, 0) - env(safe-area-inset-top) - env(safe-area-inset-bottom));

    .NotificationList {
        position: absolute;
        inset-block-start: 16px;
        inset-inline: 50% initial;
        transform: translateX(-50%);
        width: 100%;
        max-width: 442px;
        align-items: center;

        @include mobile {
            padding-inline: 16px;
        }

        .Notification {
            max-width: inherit;
        }

        @include desktop {
            inset-block-start: 64px;
        }
    }

    &-Logo {
        display: flex;
        margin-block-end: 32px;

        @include desktop {
            margin-block-end: 64px;
        }

        &Wrapper {
            margin-inline: auto;
            height: var(--header-logo-height);
            width: var(--header-logo-width);
        }
    }

    &-Container {
        padding-block: 32px;

        @include desktop {
            min-height: calc(100vh - var(--header-top-menu-height));
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding-block: 64px;
        }
    }

    &-Wrapper {
        width: 100%;
    }

    &-InnerWrapper {
        overflow: hidden;
        padding-inline: 16px;
        padding-block: 32px 64px;

        @include desktop {
            padding-inline: 64px;
            padding-block: 64px 96px;
        }

        background-color: map-get($default-neutral, 10);

        .MyAccountOverlay-Additional {
            display: none;
        }

        .Form {
            margin-inline: auto;
            max-width: 442px;

            .Field-Wrapper {
                .Field:first-of-type {
                    margin-block-start: 0;
                }

                &:not(:first-of-type) {
                    .Field {
                        margin-block-start: 48px;
                    }
                }
            }

            .Button_likeLink {
                margin-block-start: 32px;

                @include desktop {
                    margin-block-start: 24px;
                }
            }

            .MyAccount-Buttons,
            .MyAccountOverlay-Buttons,
            .MyAccountOverlay-SignInButton {
                margin-block-start: 48px;

                .Button {
                    --button-height: 56px;
                    --button-hover-height: 56px;

                    width: 100%;

                    &:first-of-type {
                        margin-block-start: 0;
                    }
                }
            }
        }
    }

    &-Head {
        text-align: center;
        margin-block-end: 32px;

        @include desktop {
            margin-block-end: 64px;
        }
    }

    &-Label {
        margin-block: 0;
    }

    &-SubLabel {
        margin-block: 16px 0;
        font-size: 1.2rem;
        line-height: 2;

        @include desktop {
            font-size: 1.4rem;
            line-height: 2;
        }
    }
}
