@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --form-background: #{map-get($default-neutral, 10)};
    --form-min-height: calc(100vh - var(--header-total-height) - 64px);
}

.MyAccountForm {
    &:not(&_success) {
        .MyAccountForm-Head {
            text-align: center;
            margin-block: 0 32px;
            text-transform: inherit;

            @include desktop {
                margin-block: 0 64px;
            }
        }
    }

    &_success {
        text-align: center;

        .MyAccountForm-Head {
            margin-block: 0 24px;
        }

        p {
            color: #{map-get($default-neutral, 700)};
            margin-block: 0 32px;

            @include desktop {
                margin-block: 0 48px;
            }
        }

        .SuccessIcon {
            margin-block-end: 32px;
            height: 80px;
            width: 80px;
            color: #{map-get($default-success, 600)};
        }
    }

    &-Label {
        font-size: 1.4rem;
        line-height: 2;

        @include mobile {
            margin-block-end: 8px;
        }

        @include desktop {
            font-size: 1.6rem;
            line-height: 1.75;
        }
    }

    &-Wrapper {
        --content-wrapper-width: 1142px;
        --content-wrapper-padding-inline: 24px;

        background-color: var(--form-background);
        padding-block: 24px;

        @include mobile {
            min-height: var(--form-min-height);
        }

        @include desktop {
            --content-wrapper-padding-inline: 116px;

            padding-block: 58px 104px;
        }

        .FieldForm {
            &-Fields {
                margin-block-end: 32px;

                @include desktop {
                    display: grid;
                    align-items: flex-start;
                    grid-column-gap: 48px;
                    grid-template-columns: auto 1fr;
                    margin-block-end: 64px;
                }

                .Field {
                    --input-padding-inline: 24px;

                    margin-block-start: 0;

                    &-SubLabelContainer {
                        margin-block-start: 16px;
                    }

                    &-SubLabel {
                        color: #{map-get($default-neutral, 600)};
                        font-weight: 400;
                        font-size: 1.2rem;
                        line-height: 2;

                        @include desktop {
                            font-size: 1.4rem;
                            line-height: 2;
                        }
                    }

                    &-Wrapper {
                        &_type_select {
                            margin-block-end: 24px;

                            @include desktop {
                                margin-block-end: 48px;
                            }
                        }
                    }

                    &_type_textarea {
                        display: flex;

                        & > textarea {
                            padding: var(--input-padding-inline);
                            min-height: 248px;
                            width: 100%;

                            @include desktop {
                                min-height: 192px;
                            }
                        }
                    }
                }
            }
        }

        .Button {
            width: 100%;

            .EmailIcon {
                margin-inline-end: 10px;

                @include desktop {
                    margin-inline-end: 18px;
                }
            }
        }
    }

    .Button {
        --button-height: 56px;
        --button-hover-height: 56px;
    }
}
