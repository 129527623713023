@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountOrderHistory {
    &-SendMessage {
        display: grid;
        grid-template-columns: 20px auto;
        grid-column-gap: 8px;
        align-items: center;
        word-break: keep-all;
        white-space: nowrap;
    }

    &-Status {
        display: flex;
        align-items: center;
        word-break: keep-all;
        white-space: nowrap;
    }

    &-Dot {
        height: 8px;
        width: 8px;
        min-width: 8px;
        display: block;
        border-radius: 100%;
        margin-inline-end: 8px;

        &_pending {
            background-color: $default-secondary-base-color;
        }

        &_verify {
            background-color: $default-primary-base-color;
        }

        &_sent {
            background-color: $default-success-base-color;
        }

        &_canceled {
            background-color: $default-error-light-color;
        }
    }

    .MyAccountOrderHistoryList,
    .MyAccountOrderHistoryProducts,
    .MyAccountOrderHistoryOrders {
        li {
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
            padding-inline: 0;

            &:last-of-type {
                margin-block-end: 0;
            }

            &:not(:last-of-type) {
                margin-block-end: 24px;
            }

            &::before {
                display: none;
            }
        }

        &Mobile {
            &-Label {
                li {
                    color: var(--table-head-color);
                }
            }
        }

        &-Table {
            hr {
                @include desktop {
                    height: 1px;
                    width: 100%;
                    background-color: map-get($default-neutral, 100);
                    margin-block-end: 4px;
                }
            }

            &_contractor_id_we,
            &_order,
            &_sales_department {
                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 24px;
                }

                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 24px;
                }
            }

            &_increment_id,
            &_created_at {
                @include desktop {
                    @include size(170px);
                }
            }

            &_name {
                @include desktop {
                    @include size(auto, 158px, 195px);
                }
            }

            &_number {
                @include desktop {
                    @include size(140px);
                }
            }

            &_order {
                @include desktop {
                    @include size(175px);
                }
            }

            &_quanitity {
                @include size(120px);
            }

            &_sales_department {
                @include desktop {
                    @include size(150px);
                }
            }

            &_incoterms {
                @include size(130px);
            }

            &_order_number,
            &_transport,
            &_delivery {
                @include size(170px);
            }

            &_invoice_numbers {
                @include size(140px);
            }

            &_customer_email {
                @include desktop {
                    @include size(auto, 200px, fit-content);
                }
            }

            &_customer_comment {
                @include desktop {
                    @include size(auto, 180px);
                }
            }

            &_contractor_id_we {
                @include desktop {
                    @include size(auto, 185px, 185px);
                }
            }

            &_actions {
                @include desktop {
                    @include size(45px);

                    vertical-align: middle;
                }
            }

            &_delivery_status {
                @include size(150px, 150px, 200px);
            }

            &_order_status {
                @include desktop {
                    @include size(#{'max(350px)'}, 150px, 350px);

                    padding-inline-end: 0;
                }
            }

            &_message {
                @include desktop {
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }
            }

            &_attachments {
                @include desktop {
                    text-align: center;
                }
            }

            &_order_number,
            &_invoice_numbers,
            &_transport_numbers,
            &_request_delivery_date,
            &_destination_country,
            &_delivery_numbers {
                word-break: keep-all;
                white-space: nowrap;

                li {
                    word-break: keep-all;
                    white-space: nowrap;
                }
            }
        }
    }

    .MyAccountOrderHistoryList {
        &Mobile {
            &-Item {
                grid-template-columns: 1fr;
            }

            &-Value {
                font-weight: 600;
            }
        }

        &-Row:not(:last-of-type) {
            .MyAccountOrderHistoryList-Cell[colspan].MyAccountTable-Cell_isCollapsed {
                padding-block-end: 50px;
            }
        }

        &-Head,
        &-Cell {
            font-size: 14px;
            line-height: 2;
            padding: 16px;
            border-block: 0;
            border-right: 0;

            &:first-of-type {
                border-left: 0;
            }

            &:last-child:not([colspan]) {
                text-align: end;

                .MyAccountTable-Toggle {
                    margin-inline-start: auto;
                }
            }
        }

        &-Head {
            background-color: map-get($default-neutral, 800);
            color: var(--color-white);
        }

        &-Cell {
            font-weight: 600;

            &.MyAccountTable-Cell_isCollapsed {
                padding: 0;
            }
        }
    }

    .MyAccountOrderHistoryProducts {
        &Mobile.MyAccountTableMobile_isCollapsedParent {
            &::before {
                content: '';
                position: absolute;
                inset-block: 100% -27px;
                inset-inline-start: 28px;
                border-left: 2px dashed map-get($default-neutral, 200);
            }

            &::after {
                content: '';
                position: absolute;
                inset-block-end: -27px;
                inset-inline: 28px 0;
                border-top: 2px dashed map-get($default-neutral, 200);
            }
        }

        &Mobile.MyAccountTableMobile_isCollapsed {
            .MyAccountTable-Wrapper {
                padding-inline-start: 76px;
            }
        }

        &Mobile:not(.MyAccountTableMobile_isCollapsed) {
            background-color: $white;
            border: 1px solid map-get($default-neutral, 200);
        }

        &-Row {
            .MyAccountOrderHistoryProducts-Head,
            .MyAccountOrderHistoryProducts-Cell {
                &[colspan] {
                    border-top: var(--table-body-border);
                    border-top-color: var(--table-body-border-color);
                }

                border-bottom: 0;
            }

            &:last-child {
                .MyAccountTable > .MyAccountTable-Wrapper {
                    &::before {
                        inset-block-end: calc(100% - 27px);
                    }
                }

                .MyAccountOrderHistoryProducts-Head,
                .MyAccountOrderHistoryProducts-Cell {
                    &:not(&[colspan]) {
                        border-bottom: var(--table-body-border);
                        border-bottom-color: var(--table-body-border-color);
                    }

                    &[colspan] {
                        .MyAccountOrderHistoryOrders-Head,
                        .MyAccountOrderHistoryOrders-Cell {
                            border-bottom: var(--table-body-border);
                            border-bottom-color: var(--table-body-border-color);
                        }

                        border-bottom: 0;
                    }
                }
            }
        }

        &-Head,
        &-Cell {
            padding: 12px 16px;
            font-size: 12px;
            font-weight: 400;
            line-height: 2;

            li {
                font-size: 12px;
                font-weight: 400;
                line-height: 2;
                padding-inline: 0;
                margin-block-end: 0;

                &:not(:last-of-type) {
                    margin-block-end: 4px;
                }

                &::before {
                    display: none;
                }
            }

            &:not(:last-of-type) {
                border-right: 0;
            }
        }

        &-DeliveryAddress {
            font-size: 12px;
            line-height: 2;
            text-align: start;
            cursor: pointer;

            @include mobile {
                font-size: 14px;
            }

            .ChevronIcon {
                height: 8px;
                color: var(--primary-base-color);
            }
        }

        &-Head {
            li {
                color: var(--table-head-color);
            }

            color: var(--table-head-color);
        }

        &-Cell {
            padding: 16px 24px;

            & > .MyAccountTable {
                & > .MyAccountTable-Wrapper {
                    overflow-x: initial;
                }
            }

            &.MyAccountTable-Cell_isCollapsed {
                padding-block: 0;
                padding-inline: 54px 0;
                border-inline: 0;
            }

            .MyAccountTable-Wrapper {
                &::before {
                    content: '';
                    position: absolute;
                    inset-block: 0;
                    inset-inline-start: -17px;
                    border-left: 2px dashed map-get($default-neutral, 200);
                }

                &::after {
                    content: '';
                    position: absolute;
                    inset: 27px 0 0 -17px;
                    border-top: 2px dashed map-get($default-neutral, 200);
                }
            }
        }
    }

    .MyAccountOrderHistoryOrders {
        &-Head,
        &-Cell {
            font-size: 12px;
            font-weight: 400;
            line-height: 2;

            hr {
                height: 1px;
                width: 100%;
                margin-block-end: 4px;
            }

            li {
                font-size: 12px;
                font-weight: 400;
                line-height: 2;
                padding-inline: 0;
                margin-block-end: 0;

                &:not(:last-of-type) {
                    margin-block-end: 4px;
                }

                &::before {
                    display: none;
                }
            }

            &:not(:last-of-type) {
                border-right: 0;
            }
        }

        &-Row {
            &:last-of-type {
                .MyAccountOrderHistoryOrders-Cell {
                    border-bottom: 0;
                }
            }
        }

        &-Head {
            border-right-color: map-get($default-primary, 700);
            background-color: map-get($default-primary, 700);
            color: var(--color-white);
            border-top: 0;

            li {
                color: var(--color-white);
            }

            hr {
                background-color: transparentize($white, 0.9);
            }
        }

        &-Cell {
            hr {
                background-color: map-get($default-neutral, 100);
            }
        }
    }
}
