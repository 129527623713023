@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountOverlay {
    &-Consents {
        margin-block-start: 48px;

        .Notification {
            margin-block-end: 0;
            max-width: initial;
            width: 100%;

            & + .Consents {
                margin-block-start: 24px;
            }
        }
    }

    &-AdditionalInfo {
        font-size: 14px;
        text-align: center;
        margin-block-start: 48px;
    }
}
