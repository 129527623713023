@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountPartners {
    @include desktop {
        --accordion-left-margin: 116px;
    }
    --accordion-left-margin: 26px;

    .Accordion {
        & > .Accordion {
            --accordion-header-backgroud: #{map-get($default-primary, 400)};
            --accordion-header-border: #{map-get($default-primary, 400)};
            --accordion-header-color: var(--color-white);

            --accordion-toggle-backgroud: #{map-get($default-primary, 400)};
            --accordion-toggle-border: var(--color-white);
            --accordion-toggle-color: var(--color-white);

            --accordion-toggle-hover-backgroud: var(--color-white);
            --accordion-toggle-hover-border: var(--color-white);
            --accordion-toggle-hover-color: #{map-get($default-primary, 400)};

            &::before {
                content: '';
                position: absolute;
                inset-block-end: 0;
                inset-inline-start: calc(var(--accordion-left-margin) * 0.5 * -1);
                width: 0;
                height: calc(100% + 30px);
                border-left: 2px dashed map-get($default-neutral, 200);

                @include desktop {
                    inset-inline-start: calc(var(--accordion-left-margin) * 0.5 * -1);
                    height: calc(100% + 20px);
                }
            }

            &::after {
                content: '';
                position: absolute;
                height: 0;
                inset-block-start: 24px;
                inset-inline-start: calc(var(--accordion-left-margin) * 0.25 * -1);
                width: var(--accordion-left-margin);
                border-top: 2px dashed map-get($default-neutral, 200);

                @include desktop {
                    inset-inline-start: calc(var(--accordion-left-margin) * 0.45 * -1);
                    width: var(--accordion-left-margin);
                }
            }

            &:last-child {
                &::before {
                    inset-block-start: -28px;
                    height: 54px;
                }
            }

            & > .Accordion {
                --accordion-header-backgroud: #{map-get($default-primary, 600)};
                --accordion-header-border: #{map-get($default-primary, 600)};
                --accordion-toggle-backgroud: #{map-get($default-primary, 600)};
                --accordion-toggle-hover-color: #{map-get($default-primary, 600)};

                &-Header {
                    height: 48px;
                    z-index: 1;

                    .Accordion-Toggle {
                        height: 32px;
                    }
                }

                & > .Accordion {
                    --accordion-header-backgroud: #{map-get($default-primary, 800)};
                    --accordion-header-border: #{map-get($default-primary, 800)};
                    --accordion-toggle-backgroud: #{map-get($default-primary, 800)};
                    --accordion-toggle-hover-color: #{map-get($default-primary, 800)};
                }
            }

            margin-inline-start: var(--accordion-left-margin);
        }
    }

    &-Detail {
        &_layout_row {
            @include desktop {
                display: flex;
                flex-wrap: wrap;
                grid-column-gap: 56px;
                grid-row-gap: 4px;
            }
        }

        p {
            font-size: 14px;
            line-height: 2;

            &:not(:last-of-type) {
                margin-block-end: 4px;
            }

            &:last-of-type {
                margin-block-end: 0;
            }

            & > span {
                color: map-get($default-neutral, 500);
            }
        }
    }
}
