@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.MyAccountOrderHistoryActions {
    --button-border-width: 1px;
    --hollow-button-padding: 16px 24px;
    --hollow-button-border: #{map-get($default-primary, 800)};
    --hollow-button-color: #{map-get($default-primary, 800)};
    --hollow-button-hover-padding: 16px 24px;
    --hollow-button-hover-border: #{map-get($default-primary, 800)};
    --hollow-button-hover-background: #{map-get($default-primary, 800)};

    vertical-align: middle;

    .Button {
        &_isEmpty {
            &:not(.Button_isSecondary):not([disabled]):hover {
                background-color: var(--hollow-button-background);
                color: var(--hollow-button-color);
            }
        }

        &_isLoading {
            &:not(.Button_isSecondary):not([disabled]):hover {
                background-color: var(--hollow-button-background);
            }

            .Loader-Scale {
                --loader-scale: 0.5;
            }
        }
    }

    &-Reference {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $default-primary-base-color;

        @include button-transition;

        .AttachmentIcon {
            height: 20px;
            width: 16px;
        }

        & > span {
            margin-inline-start: 8px;
        }

        &:not([disabled]) {
            cursor: pointer;

            &:hover {
                color: map-get($default-primary, 800);

                .MyAccountOrderHistoryActions-Button > span {
                    background-color: map-get($default-primary, 800);
                }
            }
        }

        &_isVisible {
            color: map-get($default-primary, 800);

            .MyAccountOrderHistoryActions-Button > span {
                background-color: map-get($default-primary, 800);
            }
        }
    }

    &-Actions {
        width: 248px;

        & > .Button {
            justify-content: flex-start;
            width: 100%;
            text-align: start;
            text-transform: initial;

            &_isOpen {
                .ChevronIcon {
                    margin-inline-end: 14px;
                }
            }

            &:not(.Button_isOpen) {
                .ChevronIcon {
                    margin-inline-start: 14px;
                }
            }

            &:last-child &:not(:last-child) {
                border-bottom: 0;
            }

            .ChevronIcon {
                height: 10px;
                width: 10px;
            }

            .SuccessIcon {
                margin-inline-end: 16px;
            }

            &_isEmpty {
                cursor: default;

                &:not(:first-child) {
                    border-top: 0;
                }

                &:hover {
                    background-color: var(--hollow-button-background);
                    border-color: var(--hollow-button-border);
                    color: var(--hollow-button-color);
                }
            }

            &_isConfirmed {
                cursor: default;
                --hollow-button-background: #{$white};
                --hollow-button-border: #{$default-success-base-color};
                --hollow-button-color: #{$default-success-base-color};
                --hollow-button-hover-background: #{$white};
                --hollow-button-hover-border: #{$default-success-base-color};
                --hollow-button-hover-color: #{$default-success-base-color};
            }
        }
    }

    &-Button {
        display: grid;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 12px;

        & > span {
            height: 4px;
            width: 4px;
            display: block;
            border-radius: 100%;
            background-color: $default-primary-base-color;

            @include button-transition;

            &:not(:last-child) {
                margin-block-end: 6px;
            }
        }
    }

    &-Attachments,
    &-Deliveries {
        background-color: var(--hollow-button-background);
        border-width: var(--button-border-width);
        border-style: solid;
        border-color: var(--hollow-button-border);
        border-top: 0;
        padding-block: 8px;
        max-height: 150px;
        overflow-y: auto;
        overflow-x: hidden;

        &_onlyAttachments {
            border-top-width: var(--button-border-width);
            border-top-style: solid;
            border-top-color: var(--hollow-button-border);
        }

        li {
            padding-inline-start: 0;

            &::before {
                display: none;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    &-Deliveries {
        & > .Button {
            --hollow-button-padding: 4px 24px;
            --hollow-button-hover-padding: 4px 24px;
            --button-border-width: 0;
            --hollow-button-height: auto;
            --hollow-button-hover-height: auto;

            font-size: 12px;
            line-height: 1.75;
            width: 100%;
            text-align: start;
            justify-content: flex-start;
            text-transform: initial;

            .SuccessIcon {
                margin-inline-end: 8px;
                height: 14px;
                width: 14px;
            }

            &_isConfirmed {
                cursor: default;
                --hollow-button-background: #{$white};
                --hollow-button-border: #{$default-success-base-color};
                --hollow-button-color: #{$default-success-base-color};
                --hollow-button-hover-background: #{$white};
                --hollow-button-hover-border: #{$default-success-base-color};
                --hollow-button-hover-color: #{$default-success-base-color};
            }
        }
    }

    &-Attachment {
        display: grid;
        grid-template-columns: 10px auto;
        grid-column-gap: 16px;
        align-items: center;
        padding-block: 4px;
        padding-inline: 24px;
        font-size: 12px;
        line-height: 1.75;
        color: var(--color-black);
        text-decoration: underline;

        & > span {
            white-space: nowrap;
            word-break: keep-all;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
